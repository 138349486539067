<template>
    <uw-content title="退货订单">

        <!-- 表格 -->
        <uw-table>

            <!-- 检索 -->
            <template slot="search">
                <el-input @input="$lodash.debounce(TableSearch, 500)" v-model="search.number" clearable  size="mini" placeholder="退货单编号查询"></el-input>
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 表数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    height="100%">

                    <!-- 序号 -->
                    <vxe-column width="60" type="seq" title="序号"></vxe-column>

                    <!-- 审批状态 -->
                    <vxe-column width="90" title="审核状态" align="center">
                        <template #default="{ row }">
                            <el-tag v-if="row.confied_state == 0" size="mini" effect="dark" type="info">待审核</el-tag>
                            <el-tag v-if="row.confied_state == 1" size="mini" effect="dark">已同意</el-tag>
                            <el-tag v-if="row.confied_state == 2" size="mini" effect="dark" type="danger">已拒绝</el-tag>
                        </template>
                    </vxe-column>

                    <!-- 采购单编号 -->
                    <vxe-column min-width="160" title="退货单编号">
                        <template #default="{ row }">
                            <el-link @click="$refs.backDrawer.Open(row.id)" type="primary">{{ row.number }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 基础信息 -->
                    <vxe-column min-width="100" field="date" title="退货日期"></vxe-column>
                    <vxe-column min-width="100" field="money" title="退货金额"></vxe-column>
                    <vxe-column min-width="120" field="reason" title="退货原因"></vxe-column>
                    <vxe-column min-width="120" field="describe" title="备注"></vxe-column>

                    <!-- 系统信息 -->
                    <vxe-column width="140" field="updated_at" title="创建时间"></vxe-column>
                    <vxe-column width="80" field="updated_user.name" title="创建人"></vxe-column>
                    <vxe-column width="140" field="created_at" title="更新时间"></vxe-column>
                    <vxe-column width="80" field="created_user.name" title="更新人"></vxe-column>

                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>

        </uw-table>

        <!-- 组件 -->
        <ims-product-back-drawer ref="backDrawer" @onChange="TableSearch" />

    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            // 窗口
            loading: false,

            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                number: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        // 供应商 - 查询
        TableSearch() {
            // 切换加载状态
            this.loading = true

            this.$http.post(
                '/9api/ims/product-back/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['created_user', 'updated_user']
                }
            ).then(rps => {
                
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        }
    }

}
</script>